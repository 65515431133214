module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.12.0__encoding@0._ldajwwwkg64x4yirc5tdi4kmee/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-167628835-2"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-jss@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.12.0__encoding@0.1.13_rea_yty4w2qw7pi2fiyokc4tx7yjwa/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.12.0__encoding@0.1.13__lcluybhu4gpn4el264i5h26vaa/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.12.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._qigci4motqmxuluu25hxzdxxpe/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
